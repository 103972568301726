export type PromotionCode = {
  id: string;
  code: string;
  formattedPromotionLabel: string;
};

export function usePromotionCode(id: string) {
  const i18n = useI18n();
  const { data } = useFetch(`/api/promotion-codes/${id}`, { deep: false });

  return computed<PromotionCode | null>(() => {
    if (!data.value) return null;

    return {
      id: data.value.id,
      code: data.value.code,
      formattedPromotionLabel:
        !!data.value.coupon.amount_off && data.value.coupon.currency
          ? useI18nMoney(
              {
                amount: data.value.coupon.amount_off / 100,
                currency: data.value.coupon.currency,
              },
              { i18n },
            ).value
          : `${data.value.coupon.percent_off}%`,
    };
  });
}
