<template>
  <lazy-promotion-code-share-dialog
    v-if="promotionCode && mode === 'SHARE'"
    :model-value="true"
    :promotion-code="promotionCode"
    @close="onDialogClose"
  />

  <lazy-promotion-code-use-dialog
    v-if="promotionCode && mode === 'USE'"
    :model-value="true"
    :promotion-code="promotionCode"
    :shared-by="$route.query.shared_by as string"
    @close="onDialogClose"
  />
</template>

<script lang="ts" setup>
import { usePromotionCode } from "~/business-areas/promotion-code/composables/promotion-code.hook";
import type { PromotionCodeVisibilityMode } from "~/business-areas/promotion-code/promition-code.model";

const route = useRoute();
const promotionCodeId = route.query.promotion_code as string;
const mode: PromotionCodeVisibilityMode =
  (route.query.mode as PromotionCodeVisibilityMode) ?? "SHARE";
const promotionCode = usePromotionCode(promotionCodeId);

function onDialogClose() {
  return navigateTo({
    ...route,
    query: {
      ...route.query,
      promotion_code: undefined,
      mode: undefined,
      shared_by: undefined,
    },
  });
}
</script>
